<template>
  <div>
    <v-card class="mx-auto rounded-0 elevated-rounded-card" elevation="0" :loading="isLoading">
      <div class="pt-5">
        <div :class="{ 'image-container': true }" @click="onClick(video)">
          <v-img class="white--text align-end" height="250px" :src="thumbnailLink">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <div class="time px-2 white--text">
            <p class="caption mb-0">{{ time }}</p>
          </div>
        </div>
      </div>
      <v-card-text class="text--primary px-3">
        <h3 class="video-type" :class="{ 'live-recording': isSupervisorRecording }">
          {{ video.recordingType == 'supervisor-call' ? $t('labels.supervisor_call') : $t('labels.evidence_capture') }}
        </h3>
        <div class="card-footer">
          <div id="card-info">
            <p class="subtitle-2 mb-0">{{ video.ownerName }}</p>
            <p>{{ dateAdded }}</p>
          </div>
          <div id="card-actions">
            <v-btn icon @click.prevent="downloadVideo(video)" :disabled="isLoading">
              <v-icon color="gray">{{ downloadIcon }}</v-icon>
            </v-btn>
            <v-btn icon @click="isSelectLangOpen = true">
              <v-icon color="gray">{{ certIcon }}</v-icon>
            </v-btn>
          </div>
        </div>
        <p v-if="video.status == 'processing'" class="blink-red">{{ $t('info.video_in_progress') }}</p>
      </v-card-text>
    </v-card>
    <SelectLangDialog :dialog="isSelectLangOpen" :loading="isLoading" @close="isSelectLangOpen = false"
      @confirm="lang => generatePdf(lang)" />
  </div>
</template>

<script>
import dayjs from 'dayjs';
import jsFileDownloader from 'js-file-downloader';
import relativeTime from 'dayjs/plugin/relativeTime';
import {
  mdiClose, mdiDelete, mdiDownload, mdiFileCertificateOutline,
} from '@mdi/js';
import SelectLangDialog from '../components/UI/SelectLangDialog';

dayjs.extend(relativeTime);

export default {
  data: () => ({
    isVideoOpen: false,
    closeIcon: mdiClose,
    deleteIcon: mdiDelete,
    downloadIcon: mdiDownload,
    certIcon: mdiFileCertificateOutline,
    isLoading: false,
    videoLink: false,
    thumbnailLink: '',
    isSelectLangOpen: false,
  }),
  components: {
    SelectLangDialog,
  },
  props: {
    video: {
      type: Object,
      default: () => null,
    },
  },
  async mounted() {
    this.getThumbnailLink(this.video);
  },
  methods: {
    async onClick(video) {
      this.isLoading = true;
      const videoLink = await this.$store.dispatch('videos/getVideoLink', { payload: video });
      if (videoLink) {
        const payload = {
          isOpen: true,
          thumbnailLink: this.thumbnailLink,
          dateAdded: this.dateAdded,
          videoLink,
        };
        this.$emit('onClick', payload);
      }
      this.isLoading = false;
    },
    async getThumbnailLink(payload) {
      this.thumbnailLink = await this.$store.dispatch('videos/getThumbnailLink', { payload });
    },
    downloadFile(payload) {
      this.isLoading = true;
      new jsFileDownloader({ //eslint-disable-line
        url: payload,
        timeout: 60000,
      }).then(() => {
        this.isLoading = false;
      });
    },
    async generatePdf(lang) {
      this.isLoading = true;

      try {
        const payload = { ...this.video, lang };
        const videoPdfLink = await this.$store.dispatch('videos/generatePdf', { payload });

        if (videoPdfLink) {
          this.downloadFile(videoPdfLink);
        }
        this.isLoading = false;
        this.isSelectLangOpen = false;
      } catch (_) {
        this.isLoading = false;
        this.isSelectLangOpen = false;
        this.$emit('alert', { type: 'error', message: 'Something went wrong. Please try again.' });
      }
    },
    async downloadVideo(payload) {
      this.isLoading = true;
      const videoLink = await this.$store.dispatch('videos/getVideoLink', { payload });
      if (videoLink) {
        this.downloadFile(videoLink);
      }
    },
  },
  computed: {
    dateAdded() {
      const nonEnLocale = ['fr', 'pt', 'es'];
      return (dayjs(new Date(this.video.dateAdded.seconds * 1000)))
        .format(nonEnLocale.includes(this.$i18n.locale) ? 'DD/MM/YYYY, HH:mm:ss' : 'MM/DD/YYYY, HH:mm:ss');
    },
    time() {
      return dayjs(this.video.time * 1000)
        .toISOString()
        .substr(14, 5);
    },
    isSupervisorRecording() {
      return this.video.recordingType === 'supervisor-call';
    },
  },
};
</script>

<style lang="scss" scoped>
.image-container {
  position: relative;

  .time {
    position: absolute;
    bottom: 5px;
    right: 10px;
    background-color: rgba($color: #000000, $alpha: 0.5);
    border-radius: 4px;
  }
}

.video-type {
  background-color: #2196f385;
  border-radius: 4px;
  padding: 0.2em 1em;
  font-weight: 200;

  &.live-recording {
    background-color: #ff4f007a;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.blink-red {
  color: red;
  animation: blink 3s linear infinite;
}

.elevated-rounded-card {
  font-size: initial !important;
  border-radius: 12px !important;
  box-shadow: 0 4px 10px -4px #0000004d !important;
  background-color: #f7f3f37d;
}

.card-footer {
  padding-top: 1em;
  display: flex;
  justify-content: space-between;
}
</style>
