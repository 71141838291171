<template>
  <v-row>
    <v-col v-if="this.pageSize > 0">
      <v-btn elevation="2" size="x-large" icon @click="$emit('prev')" :disabled="currentPage == 1">
        <v-icon color="info">{{ previousPage }}</v-icon>
      </v-btn>
    </v-col>
    <v-col class="page-count text-center" v-if="videos.length > 0">
      {{ currentPage }} / {{ pageSize }}
    </v-col>
    <v-col class="text-right"  v-if="this.pageSize > 0">
      <v-btn elevation="2" size="x-large" icon @click="$emit('next')" :disabled="currentPage === pageSize">
        <v-icon color="info">{{ nextPage }}</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';

export default {
  name: 'VideosPagination',
  data: () => ({
    previousPage: mdiChevronLeft,
    nextPage: mdiChevronRight,
    coordinates: '',
  }),
  props: {
    videos: {
      type: Array,
      default: () => [],
    },
    count: {
      count: Number,
      default: 10,
    },
    pageSize: {
      type: Number,
      default: 1,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
  },
};
</script>

<style scoped>
  .tooltip-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .page-count {
    font-weight: bold;
    color: #2196f3;
    font-size: 16px;
  }
</style>
