<template>
  <div class="pa-4">
    <v-alert
      v-if="isVideoError"
      color="red lighten-2"
      dark
      dismissible
    >
      {{ $t('error.play_video') }}
    </v-alert>
    <v-row>
      <v-col cols="3">
        <v-select
          :items="videoFilters"
          outlined
          dense
          single-line
          v-model="selectedItem"
          label="Select"
          @change="onSelectFilter">
          <template v-slot:item="{ item, attrs, on }">
          <v-list-item v-on="on" v-bind="{'aria-selected': selectedItem == item }"
            :class="{'selected-item': selectedItem == item }">
            <v-list-item-content>
              <v-list-item-title>
                <v-row no-gutters align="center" class="ml-3">
                  <span>{{ $t(item.name) }}</span>
                </v-row>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-slot:selection="{ active, item, attrs, on }">
          <div v-on="on" v-bind="attrs">
            {{ $t(item.name) }}
          </div>
        </template>
        </v-select>
      </v-col>
      <v-row v-if="(role === 'orgUser' || user !== null)">
      <v-col>
        <v-tabs class="mb-6">
          <v-tab @click="getUserVideos({}, false)">All</v-tab>
          <v-tab @click="getUserVideos({}, true)">Favorites</v-tab>
        </v-tabs>
      </v-col>
    </v-row>
      <v-col v-if="role === 'superAdmin' || role === 'eyeStaff'" cols="3">
      </v-col>
      <v-col v-if="this.role !== 'orgUser'" cols="4" class="ml-auto">
        <v-row align="center">
          <v-col id="userSearchContainer">
            <v-autocomplete
              v-model="user"
              :items="usersList"
              item-value="id"
              :label="$t('labels.user')"
              variant="solo-filled"
              dense
              solo
              hide-details
              no-filter
              clearable
              :return-object="true"
              @click:clear="getByOrg('', true)"
              @update:search-input="v =>  updateSearchValue(v)"
              @change="getUserVideos()"
            >
              <template v-slot:selection="{ item }">
                <v-list-item-content>
                  <v-list-item-title>
                    <span>{{ item.lastName }}, {{ item.firstName }}</span>
                    <span class="pl-2 grey--text font-italic">{{ item.phoneNumber | VMask('###-###-####') }}</span>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <span class="grey--text">{{ item.email }}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title>
                    <span>{{ item.lastName }}, {{ item.firstName }}</span>
                    <span class="pl-2 grey--text font-italic">{{ item.phoneNumber | VMask('###-###-####') }}</span>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <span class="grey--text">{{ item.email }}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
              <template v-slot:no-data>
                <small class="px-4 font-weight-medium">{{ $t('info.no_data') }}</small>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <VideosPagination
      :videos="videoData"
      :count="videoCount"
      :currentPage="currentPage"
      :pageSize="pageSize"
      @prev="getPrev"
      @next="getNext"
    />
    <v-row v-if="videoData.length > 0" >
      <v-col v-for="video in videoData" :key="video.id" xs="12" md="4" lg="3" xl="2">
        <VideoCard
          :video="video"
          @alert="showAlert"
          @onClick="(payload) => onVideoClick(payload, video)"/>
      </v-col>
    </v-row>
    <v-row v-if="pageSize > 0 && videoData.length === 0"  class="justify-center">
      <v-progress-circular
        align="center"
        color="primary"
        indeterminate
        :size="52"
        :width="6"
      ></v-progress-circular>
    </v-row>
    <p v-if="pageSize === 0" class="text-center">{{ $t('labels.no_result') }}</p>
    <VideosPagination
      v-if="videoData.length > 0"
      :videos="videoData"
      :count="videoCount"
      :currentPage="currentPage"
      :pageSize="pageSize"
      @prev="getPrev"
      @next="getNext"
    />
    <ConfirmDialog ref="confirmDiaglog" />

    <v-snackbar
      v-model="isAlertOpen"
      :timeout="3000"
      outlined
      :color="alert.type"
    >
      {{ alert.message }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          icon
          v-bind="attrs"
          @click="isAlertOpen = false"
        >
          <v-icon>{{ closeIcon }}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <!-- Video player dialog -->
    <v-dialog content-class="elevation-0" v-model="isVideoOpen" :max-width="maxWidth">
      <v-row justify="center"
        class="video-dialog text-right">
        <v-col cols="12" md="6" lg="6" >
          <video
            @error="handleVideoError"
            :key="selectedVideo.id"
            autoplay="true"
            ref="video"
            controls
            preload="metadata"
            class="fullscreen-video"
            :src="videoLink"
            type="video/mp4"
            :poster="thumbnailLink">
            {{ $t('error.video_browser_error') }}
          </video>
        </v-col>
        <v-col cols="12" md="6" lg="3" class="video-details text-right">
          <v-btn icon @click="closeVideo">
            <v-icon color="red">{{ closeIcon }}</v-icon>
          </v-btn>
          <div class="text-left">
            <div>{{ $t('labels.date_added') }}: <span class="details-label">{{ dateAdded }}</span></div>
            <div>{{ $t('labels.username') }}: <span class="details-label">{{ selectedVideo.ownerName }}</span></div>
            <div>{{ $t('labels.recording_type') }}:
              <span class="details-label">
              {{ selectedVideo.recordingType == 'supervisor-call' ? $t('labels.supervisor_call') : $t('labels.evidence_capture') }}
              </span>
            </div>
            <div>{{ $t('labels.device_id') }}: <span class="details-label">{{ selectedVideo.deviceId }}</span></div>
            <div>{{ $t('labels.org_id') }}: <span class="details-label">{{ selectedVideo.organizationId }}</span></div>
            <div>{{ $t('labels.room_id') }}: <span class="details-label">{{ videoDetails.roomSID }}</span></div>
            <div>{{ $t('labels.sid') }}: <span class="details-label">{{ videoDetails.sid }}</span></div>
          </div>
        </v-col>
      </v-row>
    </v-dialog>
  </div>
</template>

<script>
import { mdiFilterOffOutline, mdiClose } from '@mdi/js';
import sortBy from 'lodash.sortby';
import {
  mapState,
  mapActions,
  mapMutations,
  mapGetters,
} from 'vuex';
import VideoCard from '@/components/VideoCard';
import ConfirmDialog from '@/components/UI/ConfirmDiaglog';
import VideosPagination from '@/components/UI/VideosPagination';

export default {
  data: () => ({
    organization: null,
    user: null,
    clearIcon: mdiFilterOffOutline,
    closeIcon: mdiClose,
    isAlertOpen: false,
    alert: { type: '', message: '' },
    userSearch: '',
    videoCount: 10,
    isUserVideo: false,
    selectedItem: { id: 1, name: 'labels.all', filterName: 'all' },
    videoFilters: [
      { id: 1, name: 'labels.all', filterName: 'all' },
      { id: 2, name: 'labels.supervisor_call', filterName: 'supervisor-call' },
      { id: 3, name: 'labels.evidence_capture', filterName: 'evidence-capture' },
    ],
    videoData: [],
    isFavorite: false,
    clearUser: false,
    isVideoOpen: false,
    selectedVideo: {},
    videoLink: null,
    thumbnailLink: '',
    videoDetails: {},
    dateAdded: '',
    isVideoError: false,
  }),
  components: {
    VideoCard,
    ConfirmDialog,
    VideosPagination,
  },
  watch: {
    selectedOrg(newVal) {
      if (this.role !== 'orgUser') {
        this.setPage(1);
        this.user = null;
        this.isUserVideo = false;
        this.organization = newVal;
        this.getVideosByOrg({ orgId: newVal, limit: this.videoCount, filter: this.selectedItem.filterName });
      }
    },
    recordedVideos(newVal) {
      this.videoData = newVal;
    },
    isVideoOpen(val) { // video player dialog state
      if (!val) {
        this.$refs.video.pause();
        this.$refs.video.removeAttribute('src');
        this.$refs.video.load();
      }
    },
  },
  mounted() {
    this.$root.$confirmDiaglog = this.$refs.confirmDiaglog.open;
    this.initData();
    this.resizeScreenHandler();
  },
  methods: {
    ...mapActions('videos', ['getVideosByUser', 'getVideosByOrg']),
    ...mapActions('users', ['getUsers']),
    ...mapActions('organizations', ['getOrganizations', 'setSelectedOrganization']),
    ...mapMutations('videos', { setPage: 'SET_PAGE', setVideoState: 'SET_STATE' }),
    async onVideoClick(payload, video) { // video player on click video card
      this.videoLink = null;
      this.selectedVideo = { ...video };
      this.videoDetails.roomSID = this.selectedVideo.twilioComposeVideo.roomSid;
      this.videoDetails.sid = this.selectedVideo.twilioComposeVideo.sid;
      this.videoLink = payload.videoLink;
      if (this.videoLink) {
        this.isVideoOpen = payload.isOpen;
        this.thumbnailLink = payload.thumbnailLink;
        this.dateAdded = payload.dateAdded;
        this.isVideoError = false;
      }
    },
    closeVideo() {
      this.$refs.video.pause();
      this.$refs.video.removeAttribute('src');
      this.isVideoOpen = false;
    },
    handleVideoError() {
      this.$refs.video.pause();
      this.$refs.video.removeAttribute('src');
      this.isVideoOpen = false;
      this.isVideoError = true;
    },
    updateSearchValue(v) {
      this.clearUser = false;
      this.userSearch = v;
      // reset page number to start to page one in organization video
      // previously the data displayed is for a specific user
    },
    fetchVideo(id, status) {
      if (this.isUserVideo) {
        this.getByUser({ id, status, filter: this.selectedItem.filterName }, this.isFavorite);
      } else {
        this.getByOrg(status);
      }
    },
    getPrev() {
      if (this.currentPage !== 1) {
        this.setPage(this.currentPage - 1);
        const id = this.user !== null ? this.user.id : this.claims.user_id;
        if (this.videoData.length === 0) {
          this.fetchVideo(id, 'back');
        } else {
          this.fetchVideo(id, 'prev');
        }
      }
    },
    getNext() {
      if (this.videoData.length === this.videoCount) {
        const id = this.user !== null ? this.user.id : this.claims.user_id;
        this.setPage(this.currentPage + 1);
        this.fetchVideo(id, 'next');
      }
    },
    async getByUser(payload) {
      if (payload) {
        this.isUserVideo = true;
        this.unsubscribe();
        await this.getVideosByUser({
          id: payload.id,
          limit: this.videoCount,
          ...payload,
          filter: payload.filter ?? this.selectedItem.filterName,
        });
        this.videoData = this.recordedVideos;
      }
    },
    async getByOrg(status, isClearedSearchField = false) {
      this.isUserVideo = false;
      this.user = null;
      this.userSearch = '';
      // this condition detect if the search field for user name is cleared, then set the page number to 1
      // previously the data displayed is for the specific user
      if (isClearedSearchField) {
        this.clearUser = true;
        this.setPage(1);
      }
      if (this.organization || this.user?.id) {
        this.unsubscribe();
        await this.getVideosByOrg({
          orgId: this.organization || '', filter: this.selectedItem.filterName, limit: this.videoCount, status,
        });
        this.videoData = this.recordedVideos;
      }
    },
    getUserVideos(payload, isFavorite = false) {
      this.setPage(1);
      if (this.user) {
        this.isFavorite = isFavorite;
        const data = {
          ...payload,
          id: this.user !== null ? this.user.id : this.claims.user_id,
        };
        if (isFavorite) {
          if (this.currentPage > 1) {
            this.setPage(1);
          }
          this.getByUser({ ...data, favorite: true });
        } else {
          this.getByUser(data);
        }
      }
    },
    async unsubscribe() {
      if (this.unsubVideos) {
        await this.unsubVideos();
      }
    },
    showAlert(alert) {
      this.isAlertOpen = true;
      this.alert = alert;
    },
    noDash(pn) {
      return pn.replace(/-/g, '');
    },
    resizeScreenHandler() {
      const sreenWidth = window.innerWidth;
      if (this.currentPage > 1) {
        this.setPage(1);
      }
      if (sreenWidth <= 960) {
        // mobile
        this.videoCount = 10;
      } else if (sreenWidth <= 1262) {
        // laptop or large screen tablet
        this.videoCount = 12;
      } else if (sreenWidth <= 1900) {
        // desktop
        this.videoCount = 16;
      } else {
        // large screen
        this.videoCount = 18;
      }
      if (this.isUserVideo || this.role === 'orgUser') {
        const id = this.user !== null ? this.user.id : this.claims.user_id;
        this.getByUser({ id, limit: this.videoCount, filter: this.selectedItem.filterName }, this.isFavorite);
      } else {
        this.organization = localStorage.getItem('organization') ?? null;
        this.getVideosByOrg({ orgId: this.organization || '', filter: this.selectedItem.filterName, limit: this.videoCount });
      }
    },
    initData() {
      if ((this.role !== 'orgUser' && this.role !== 'superAdmin') && this.isRecordedVideosFetched) {
        this.unsubscribe();
        this.setVideoState({ key: 'isRecordedVideosFetched', value: false });
      }
      this.userSearch = '';
      this.setSelectedOrganization();
      if (!this.isUsersInitialized && this.role !== 'orgUser') {
        this.getUsers();
      }
    },
    onSelectFilter() {
      this.setPage(1);
      this.videoData = [];
      if (this.isUserVideo || this.role === 'orgUser') {
        const id = this.user !== null ? this.user.id : this.claims.user_id;
        this.getByUser({ id, filter: this.selectedItem.filterName });
      } else {
        const orgId = localStorage.getItem('organization') ?? null;
        this.getVideosByOrg({ orgId: orgId || '', filter: this.selectedItem.filterName, limit: this.videoCount });
      }
      this.videoData = this.recordedVideos;
    },
  },
  computed: {
    ...mapState('auth', ['role', 'claims']),
    ...mapState('videos',
      [
        'recordedVideos',
        'currentPage',
        'unsubVideos',
        'isRecordedVideosFetched',
        'pageSize',
      ]),
    ...mapState('users', { isUsersInitialized: 'isInitialized', users: 'users' }),
    ...mapState('organizations',
      {
        organizations: 'organizations',
        isOrgInitialized: 'isInitialized',
        selectedOrg: 'selectedOrganization',
      }),
    ...mapGetters('users', ['filteredUsersByOrg']),
    usersList() {
      let users = [];

      if ((this.role === 'eyeStaff' || this.role === 'superAdmin') && this.selectedOrg) {
        const filteredUsers = this.filteredUsersByOrg(this.selectedOrg);
        users = sortBy(filteredUsers, [(user) => `${user.lastName.toLowerCase()}, ${user.firstName.toLowerCase()}`]);
      }

      if (this.role === 'orgAdmin') {
        users = sortBy(this.users, [(user) => `${user.lastName.toLowerCase()}, ${user.firstName.toLowerCase()}`]);
      }

      if (this.userSearch) {
        const keyword = this.userSearch.toLowerCase();
        const numericKeyword = this.noDash(keyword);
        users = users.filter((user) => user.firstName.toLowerCase().includes(keyword)
          || user.lastName.toLowerCase().includes(keyword)
          || this.noDash(user.phoneNumber).includes(numericKeyword)
          || user.email.toLowerCase().includes(keyword));
      }

      return users;
    },
    maxWidth() {
      return `${window.innerWidth}px`;
    },
  },
  created() {
    window.addEventListener('resize', this.resizeScreenHandler);
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeScreenHandler);
  },
};
</script>

<style>
#userSearchContainer .v-list-item__content {
  flex: 2 1 !important;
}
#userSearchContainer .v-list-item__subtitle {
  min-height: 16px;
}
.video-details {
  font-size: 14px;
  background-color: #fff;
  height: 50vh;
}
.video-details div {
  color: #000000;
  padding-bottom: 10px;
}
.details-label {
  font-weight: bold;
}
</style>
